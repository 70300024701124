<template>
  <Dropdown width="lg">
    <template #button-content="{ onClickOpen }">
      <a
        href="#"
        class="flex items-center nav-link"
        @click.prevent="onClickOpen"
      >
        <!-- if bag haves items -->
        <span
          v-if="cartTotalItems > 0"
          class="absolute top-1.5 end-0 inline-flex w-2 h-2 rounded-full shadow-lg bg-primary-500 ring-1 ring-gray-400 z-40"
        />
        <BagIcon
          class="inline-block w-[1.5625rem] h-[1.5625rem] nav-link hover:opacity-60"
          :color="props.color"
        />
      </a>
    </template>

    <!-- Cart -->
    <div class="px-4 py-3">
      <!-- bag is empty -->
      <p v-if="cartTotalItems === 0" class="mt-10 text-center text-gray-400">
        {{ $t("common.your_bag_is_empty") }}
      </p>
      <!-- bag haves items -->
      <ul v-else>
        <li
          v-for="item in cartItems.slice(0, 3)"
          :key="item.id"
          class="relative flex items-center py-2 border-b"
        >
          <img
            v-if="item.attributes.picture"
            :src="item.attributes.picture"
            :alt="item.name"
            class="object-contain w-16 h-16 border-transparent me-3"
          />
          <div v-else class="flex items-center w-16 h-16 me-3">
            <ImageIcon class="h-8 mx-auto text-gray-200" />
          </div>
          <p class="mx-3 text-sm">
            {{ item.name }}
          </p>
          <a
            href="#"
            class="ms-auto text-danger"
            @click.prevent="removeCartItem(item.uuid)"
          >
            <CloseIcon class="w-6 h-6 stroke-red hover:opacity-50" />
          </a>
        </li>
      </ul>
      <!-- if it haves more than 3 items -->
      <p v-if="cartItems.length > 3" class="text-xs text-center text-gray-400">
        {{ $t("common.3_more_items_in_your_bag") }}
      </p>
      <Btn
        v-if="cartItems.length > 0"
        color="black"
        class="mt-3"
        block
        @click.prevent="prepareForCheckOut"
        v-text="$t('common.checkout')"
      />
    </div>

    <!-- User info -->
    <ul class="px-4 pb-3">
      <!-- if user is logged -->
      <li
        v-if="$store.getters['auth/isAuthenticated']"
        key="loggedIn"
        class="py-2 border-b"
      >
        <nuxt-link
          :to="$i18nPath('/my-account')"
          class="block text-gray-500 hover:no-underline"
          :prefetch="false"
        >
          {{ $store.getters["auth/loggedUser"].full_name }}
        </nuxt-link>
        <nuxt-link
          :to="$i18nPath('/my-account')"
          class="block text-blue-500 hover:no-underline hover:text-blue-300"
          :prefetch="false"
        >
          {{ $store.getters["auth/loggedUser"].email }}
        </nuxt-link>
      </li>
      <!-- if user is not logged -->
      <template v-else>
        <li class="py-2 border-b">
          <n-link
            class="text-gray-600 hover:no-underline"
            :to="$i18nPath('/login')"
            v-text="$t('common.login')"
          />
        </li>
        <li class="py-2 border-b">
          <n-link
            class="text-gray-600 hover:no-underline"
            :to="$i18nPath('/register')"
            v-text="$t('common.register')"
          />
        </li>
      </template>

      <!-- no matter if logged or not -->
      <li class="py-2">
        <n-link
          class="text-gray-600 hover:no-underline"
          :to="$i18nPath('/my-account/orders')"
          v-text="$t('common.my_orders')"
        />
      </li>
      <!--      <li class="py-2">
        <n-link
          class="text-gray-600 hover:no-underline"
          :to="$i18nPath('/my-account/bikes')"
          v-text="$t('common.my_bicycles')"
        />
      </li>-->
      <!-- if user is logged -->
      <li v-if="$store.getters['auth/isAuthenticated']" class="pt-2 border-t">
        <a
          class="text-gray-600 hover:no-underline"
          href="#"
          @click.prevent="logoutNow"
          v-text="$t('common.logout')"
        />
      </li>
    </ul>
  </Dropdown>
</template>

<script setup>
import { useCartStore } from "@/store/cart";
import { useAuthStore } from "@/store/auth";
import BagIcon from "@/components/BagIcon";
import CloseIcon from "@/assets/svg/heroicons/x-circle.svg";
import ImageIcon from "@/assets/svg/heroicons/image.svg";

const route = useRoute();
const cartStore = useCartStore();
const authStore = useAuthStore();
const router = useRouter();
const nuxtApp = useNuxtApp();

const isBag = ref(route.path.includes("bag"));

const props = defineProps({
  color: {
    type: String,
    required: false,
    default: "white",
  },
});

const cartItems = cartStore.cartItems;
const cartTotalItems = cartStore.cartTotalItems;
const cartUUID = cartStore.cartUUID;

onMounted(() => {
  if (!cartStore.cart.init) {
    cartStore.fetchCartItems();
  }
});

function removeCartItem(uuid) {
  cartStore.removeCartItem(uuid);
}
function prepareForCheckOut() {
  router.push(nuxtApp.$i18nPath(`/checkout/${cartUUID}`));
}
async function logoutNow() {
  if (nuxtApp.$intercom) {
    nuxtApp.$intercom.shutdown();
    nuxtApp.$intercom.update({});
  }
  await authStore.logout();

  await router.push(nuxtApp.$i18nPath("/"));
}
</script>

<style lang="postcss" scoped>
.nav-link {
  @apply mr-0;
}
</style>
